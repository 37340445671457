import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
  setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import "./index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Theme variables */
import "./theme/variables.css";
import { routes } from "./routes";
import { useAuthStore } from "./stores/authStore";
import { Toaster } from "react-hot-toast";
import SideMenu from "./components/SideMenu";

setupIonicReact();

const App: React.FC = () => {
  const [user, loading] = useAuthStore(state => [state.user, state.loading]);
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <IonSpinner className="w-16 h-16" color="primary" />
      </div>
    );
  }
  return (
    <IonApp>
      <Toaster position="bottom-center" />
      <IonReactRouter>
        <SideMenu />
        <IonRouterOutlet id="main">
          {routes.map((route, index) => {
            if (!user && route.path !== "/login") {
              return <Redirect to="/login" key={index} />;
            }
            return (
              <Route
                exact
                path={route.path}
                component={route.component}
                key={index}
              />
            );
          })}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;

import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonAlert
} from "@ionic/react";
import { useField } from "formik";
import { addOutline, trashOutline } from "ionicons/icons";

interface InputProps {
  label: string;
  name: string;
  disabled?: boolean;
}

const ListInput: React.FC<InputProps> = ({ disabled, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props.name);
  const [presentAlert] = useIonAlert();

  return (
    <IonList>
      <IonListHeader>
        <IonLabel color="primary">{props?.label}</IonLabel>
        <div>
          <IonButton
            color="primary"
            className="mx-3"
            fill="outline"
            disabled={disabled}
            onClick={() => {
              presentAlert({
                header: "Add to List",
                inputs: [
                  {
                    name: "item",
                    type: "text",
                    placeholder: "Item Description"
                  }
                ],
                buttons: ["Cancel", "Add"],
                onDidDismiss: async (e: any) => {
                  if (e.detail.role !== "cancel") {
                    helpers.setValue([
                      ...field.value,
                      e.detail.data.values.item
                    ]);
                  }
                }
              });
            }}
          >
            <IonIcon icon={addOutline} />
          </IonButton>
        </div>
      </IonListHeader>
      {field.value.map((item: string, index: number) => (
        <IonItem key={`${item}-${index}`}>
          <IonLabel>{item}</IonLabel>
          <IonButton
            color="danger"
            fill="outline"
            slot="end"
            onClick={e => {
              field.value = field.value.filter(
                (item_ref: string) => item_ref !== item
              );
              helpers.setValue(field.value);
            }}
          >
            <IonIcon icon={trashOutline} />
          </IonButton>
        </IonItem>
      ))}
    </IonList>
  );
};

export default ListInput;

import {
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonText
} from "@ionic/react";
import { useField } from "formik";

interface InputProps {
  label: string;
  name: string;
  disabled?: boolean;
}
const DateInput: React.FC<InputProps> = ({ label, disabled, ...props }) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <div className="flex items-center m-4">
        <p>{label}</p>
        <IonDatetimeButton
          datetime={props.name}
          disabled={disabled}
        ></IonDatetimeButton>
      </div>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          id={props.name}
          {...props}
          onIonChange={e =>
            helpers.setValue(new Date(e.detail.value as string))
          }
          value={(field.value as Date).toISOString()}
          onBlur={field.onBlur}
        />
      </IonModal>
      <IonText className="text-danger text-sm">
        {meta.touched && meta.error}
      </IonText>
    </>
  );
};

export default DateInput;

export const toCurrency = (num: number) => {
  const formatter = new Intl.NumberFormat("en-ZA", {
    style: "currency",
    currency: "ZAR"
  });
  return formatter.format(num);
};
export const fromCurrency = (curr: string) => {
  return Number(curr.replace(/[^0-9.-]+/g, "")) / 100;
};

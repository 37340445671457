import { MIN_PASSWORD_LENGTH, MIN_USERNAME_LENGTH } from "./const";

export function getUserValidationSchema(values: any) {
  return {
    username: [
      [(value: any) => !isEmpty(value), "Username is required!"],
      [
        (value: any) => value.length >= MIN_USERNAME_LENGTH,
        `Username has to be longer than ${MIN_USERNAME_LENGTH} characters!`
      ]
    ],
    email: [
      [(value: any) => !isEmpty(value), "E-mail is required!"],
      [(value: any) => isEmail(value), "E-mail is not valid!"]
    ],
    password: [
      [(value: any) => !isEmpty(value), "Password is required!"],
      [
        (value: any) => value.length >= MIN_PASSWORD_LENGTH,
        `Password has to be longer than ${MIN_PASSWORD_LENGTH} characters!`
      ]
    ],
    consent: [
      [
        (value: any) => value === true,
        "You have to give consent for us to access your Personal Information!"
      ]
    ],
    confirmTerms: [
      [
        (value: any) => value === true,
        "You have to agree with our Terms and Conditions!"
      ]
    ],
    title: [
      [(value: any) => !isEmpty(value), "Title is required!"]
      // TODO: one of the following Mr. Mrs Ms. Dr. etc
    ],
    firstName: [[(value: any) => !isEmpty(value), "First name is required!"]],
    initials: [[(value: any) => !isEmpty(value), "Initials are required!"]],
    lastName: [[(value: any) => !isEmpty(value), "Last name is required!"]],
    idNumber: [
      [(value: any) => !isEmpty(value), "ID number is required!"],
      [(value: any) => isValidIdNumber(value), "ID number not valid!"]
    ],
    dateOfBirth: [
      [(value: any) => !isEmpty(value), "Date of birth is required!"]
      // TODO: Valid date
    ],
    country: [[(value: any) => !isEmpty(value), "Country is required!"]],
    gender: [[(value: any) => !isEmpty(value), "Gender is required!"]],
    phoneNumber: [
      [(value: any) => !isEmpty(value), "Phone number is required!"]
    ],
    address: [[(value: any) => !isEmpty(value), "Address is required!"]],
    // suburb: [[(value: any) => !isEmpty(value), "Suburb is required!"]],
    city: [[(value: any) => !isEmpty(value), "City is required!"]],
    postalCode: [[(value: any) => !isEmpty(value), "Postal code is required!"]]
  };
}

function isValidIdNumber(value: any) {
  return value.toString().length === 13;
}

function isEmpty(value: any) {
  return !value || !(value && value.trim());
}

function isGreaterThan(value: any, min: number) {
  return value && value > min;
}

function isEmail(value: any) {
  const EMAIL_REGEXP = /([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/; // eslint-disable-line no-useless-escape
  return EMAIL_REGEXP.test(value);
}

export function getAssetValidationSchema(values: any) {
  return {
    name: [[(value: any) => !isEmpty(value), "Name is required!"]],
    address: [[(value: any) => !isEmpty(value), "Address is required!"]],
    city: [[(value: any) => !isEmpty(value), "City is required!"]],
    area: [[(value: any) => !isEmpty(value), "Area is required!"]],
    country: [[(value: any) => !isEmpty(value), "Country is required!"]],
    postalcode: [[(value: any) => !isEmpty(value), "Postal code is required!"]],
    value: [[(value: any) => isGreaterThan(value, 0), "Value is required!"]],
    description: [[(value: any) => !isEmpty(value), "Description is required!"]]
  };
}

export function getInvestmentValidationSchema(values: any) {
  return {
    name: [[(value: any) => !isEmpty(value), "Name is required!"]]
  };
}

export interface Transaction {
  id: string;
  investment: string;
  documents: any[];
  user: string;
  numShares: number;
  status: {
    payment: {
      updated: Date;
      complete: boolean;
    };
    documentation: {
      updated: Date;
      complete: boolean;
    };
    complete: boolean;
  };
  timestamp: Date;
}

export const emptyTransaction = {
  id: "",
  investment: "",
  documents: [],
  user: "",
  numShares: 0,
  status: {
    payment: {
      updated: new Date(),
      complete: false
    },
    documentation: {
      updated: new Date(),
      complete: false
    },
    complete: false
  },
  timestamp: new Date()
};

import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc
} from "firebase/firestore";
import create from "zustand";
import { db } from "../lib/firebase";
import { User } from "../types/user.type";

interface InvestorsStoreState {
  investors: User[];
  fetching: boolean;
  updateInvestor: (
    uid: string,
    fields: { [index: string]: any }
  ) => Promise<void>;
  getUser: (uid: string) => User | undefined;
}

export const useInvestorsStore = create<InvestorsStoreState>((set, get) => ({
  investors: [],
  fetching: true,
  updateInvestor: async (uid: string, fields: { [index: string]: any }) => {
    const ref = doc(db, "users", uid);
    await updateDoc(ref, fields);
  },
  getUser: uid => get().investors.find(i => i.uid === uid)
}));

onSnapshot(query(collection(db, "users")), querySnapshot => {
  useInvestorsStore.setState({ fetching: true });
  let tempUsers: User[] = [];

  querySnapshot.forEach(doc => {
    if (doc.id === "roles") return;
    tempUsers.push(toInvestor(doc));
  });
  useInvestorsStore.setState({ investors: tempUsers, fetching: false });
});

function toInvestor(doc: any): User {
  return { ...doc.data(), uid: doc.id };
}

import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc
} from "firebase/firestore";
import create from "zustand";
import { db } from "../lib/firebase";
import { Asset } from "../types/asset.type";

interface AssetsState {
  fetching: boolean;
  assets: Asset[];
  updateAsset: (id: string, fields: { [index: string]: any }) => Promise<void>;
  getAsset: (id: string) => Asset | undefined;
  deleteAsset: (id: string) => Promise<void>;
}

export const useAssetsStore = create<AssetsState>((set, get) => ({
  assets: [],
  fetching: true,
  updateAsset: async (id: string, fields: { [index: string]: any }) => {
    const ref = doc(db, "assets", id);
    await updateDoc(ref, fields);
  },
  getAsset: (id: string) => get().assets.find(p => p.id === id),
  deleteAsset: async (id: string) => {
    const ref = doc(db, "assets", id);
    await deleteDoc(ref);
  }
}));

onSnapshot(query(collection(db, "assets")), querySnapshot => {
  useAssetsStore.setState({ fetching: true });
  let tempAssets: Asset[] = [];

  querySnapshot.forEach(doc => {
    tempAssets.push(toAsset(doc));
  });
  useAssetsStore.setState({ assets: tempAssets, fetching: false });
});

function toAsset(doc: any): Asset {
  return { ...doc.data(), id: doc.id };
}

import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonNote,
  IonSpinner,
  useIonAlert
} from "@ionic/react";
import { FieldArray, Form, Formik, useFormikContext } from "formik";
import { addOutline, trashOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import DateInput from "../components/forms/DateInput";
import NumberInput from "../components/forms/NumberInput";
import TextInput from "../components/forms/TextInput";
import { getInvestmentValidationSchema } from "../components/forms/getValidationSchema";
import validate from "../components/forms/validate";

import Layout from "../components/Layout";

import MultipleSelectModal from "../components/MultipleSelectModal";
import SelectInput from "../components/forms/SelectInput";
import { toCurrency } from "../lib/utils";
import { useAssetsStore } from "../stores/assetsStore";
import { useInvestmentsStore } from "../stores/investmentsStore";
import { useInvestorsStore } from "../stores/investorsStore";
import { Investment } from "../types/investment.type";
import { useTransactionsStore } from "../stores/transactionsStore";
import { Transaction, emptyTransaction } from "../types/transaction.,type";

import moment from "moment";

const SingleInvestment: React.FC = () => {
  let { id } = useParams<{ id: string }>();

  const [fetching, investment, updateInvestment] = useInvestmentsStore(
    state => [
      state.fetching,
      state.investments.find(b => b.id === id),
      state.updateInvestment
    ]
  );
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const deleteInvestment = useInvestmentsStore(state => state.deleteInvestment);
  // eslint-disable-next-line
  const [getAsset, assets, updateAsset] = useAssetsStore(state => [
    state.getAsset,
    state.assets,
    state.updateAsset
  ]);
  const [getUser, allInvestors] = useInvestorsStore(state => [
    state.getUser,
    state.investors
  ]);

  const transactions = useTransactionsStore(state =>
    state.transactions.filter(t => t.investment === id)
  );
  const addTransaction = useTransactionsStore(state => state.addTransaction);
  const [transactionsToAdd, setTransactionsToAdd] = useState<Transaction[]>([]);
  const editable = !(investment?.status === "draft");
  const investable =
    investment?.status === "open" || investment?.status === "closed";

  if (fetching) {
    return (
      <Layout showToolbar={false}>
        <div className="text-center">
          <IonSpinner color="primary" />
        </div>
      </Layout>
    );
  }
  return (
    <Layout showToolbar={false}>
      {!investment ? (
        <div className="text-center">
          <IonSpinner color="primary" />
        </div>
      ) : (
        <Formik
          initialValues={{ ...investment }}
          onSubmit={async (values, { setSubmitting }) => {
            const t = toast.loading("Saving...");
            values.updatedAt = new Date();
            await Promise.all(transactionsToAdd.map(addTransaction));
            setTransactionsToAdd([]);
            const assetsAdded = values.assets.filter(
              a => !investment.assets.includes(a)
            );
            const assetsRemoved = investment.assets.filter(
              a => !values.assets.includes(a)
            );

            await Promise.all([
              ...assetsAdded.map(a =>
                updateAsset(a, { investment: values.id })
              ),
              ...assetsRemoved.map(a => updateAsset(a, { investment: "" }))
            ]);

            await updateInvestment(id, values);
            setSubmitting(false);
            history.goBack();
            toast.success("Investment saved", { id: t });
          }}
          validate={validate(getInvestmentValidationSchema)}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => (
            <Form>
              <div className="pb-20 max-w-3xl mx-auto">
                <h1 className="text-2xl p-5 pl-4">{investment?.name}</h1>
                <p className="section-heading">Basic Info</p>
                <TextInput
                  name="name"
                  label="Name:"
                  placeholder="Name"
                  disabled={editable}
                />
                <TextInput
                  name="description"
                  label="Description:"
                  placeholder="Description"
                  disabled={editable}
                />
                <NumberInput
                  name="totalShares"
                  label="Total shares:"
                  disabled={editable}
                />
                <NumberInput
                  name="yield"
                  label="Annual Yield (%):"
                  disabled={editable}
                />
                <SelectInput
                  name="status"
                  label="Status:"
                  options={[
                    { label: "Draft", value: "draft" },
                    { label: "Listed", value: "listed" },
                    { label: "Open", value: "open" },
                    { label: "Closed", value: "closed" },
                    { label: "Cancelled", value: "cancelled" },
                    { label: "Active", value: "active" },
                    { label: "Completed", value: "completed" }
                  ]}
                />
                <p className="section-heading">Assets</p>
                <FieldArray
                  name="assets"
                  render={arrayhelpers => (
                    <>
                      <IonList>
                        {values.assets?.map(id => {
                          const asset = getAsset(id);
                          if (!asset) return null;
                          return (
                            <IonItem key={id}>
                              <div className="w-40">{asset.name}</div>
                              <div slot="end">{toCurrency(asset.value)}</div>

                              <IonIcon
                                icon={trashOutline}
                                slot="start"
                                color="danger"
                                onClick={() =>
                                  arrayhelpers.remove(values.assets.indexOf(id))
                                }
                                hidden={editable}
                              />
                            </IonItem>
                          );
                        })}
                        <IonItem disabled={editable}>
                          <IonButton
                            id="open-select-assets"
                            expand="full"
                            fill="solid"
                            className="w-full"
                          >
                            <IonIcon icon={addOutline} />
                          </IonButton>
                        </IonItem>
                      </IonList>

                      <MultipleSelectModal
                        addValue={arrayhelpers.push}
                        values={assets.filter(
                          p => !values.assets.includes(p.id) && !p.investment
                        )}
                        heading="Assets"
                        trigger="open-select-assets"
                        render={p => p.name}
                      />
                    </>
                  )}
                />
                <CalculateValues />
                <p className="section-heading">Dates</p>
                <div className="flex justify-between">
                  <DateInput
                    name="opensAt"
                    label="Opens at:"
                    disabled={editable}
                  />

                  <DateInput
                    name="closesAt"
                    label="Closes at:"
                    disabled={editable}
                  />
                </div>
                <div className="flex justify-between items-center">
                  <p className="section-heading">Reservations</p>
                  <p>Reserved Shares:{values.reservedShares}</p>
                </div>

                <FieldArray
                  name="reserved"
                  render={arrayhelpers => (
                    <>
                      <IonList>
                        {values.reserved?.map((res, index) => {
                          const uid = res.user;
                          const user = getUser(uid);
                          if (!user) return null;
                          return (
                            <IonItem key={index}>
                              <div className="w-full flex justify-between">
                                <div>
                                  {user.firstName} {user.lastName}
                                </div>
                                {res.waitList && <div>Waiting list</div>}
                                <div>{res.numShares} shares</div>
                                <div>
                                  {moment(res.timestamp).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </div>
                              </div>
                            </IonItem>
                          );
                        })}
                        <IonItem>
                          <Formik
                            initialValues={{
                              user: "",
                              timestamp: new Date(),
                              numShares: 0
                            }}
                            onSubmit={(newReservation, { resetForm }) => {
                              if (
                                newReservation.user === "" ||
                                newReservation.numShares === 0
                              )
                                return;

                              if (
                                investment.status === "open" ||
                                investment.status === "closed"
                              ) {
                                // if investment is full add to waiting list
                                const reservedShares = values.reserved.reduce(
                                  (curr, r) => {
                                    if (r.waitList) return curr;
                                    else return curr + r.numShares;
                                  },
                                  0
                                );
                                const availableShares =
                                  values.totalShares - reservedShares;
                                if (availableShares <= 0) {
                                  // place on the waiting list
                                  arrayhelpers.push({
                                    ...newReservation,
                                    waitList: true
                                  });
                                } else {
                                  // Allocate avaliable shares
                                  const shares = Math.min(
                                    Math.max(values.availableShares, 0),
                                    newReservation.numShares
                                  );

                                  arrayhelpers.push({
                                    ...newReservation,
                                    numShares: shares
                                  });
                                }
                              }
                              resetForm();
                            }}
                          >
                            {({ submitForm }) => (
                              <div className="flex w-full items-center justify-between">
                                <SelectInput
                                  label="User"
                                  name="user"
                                  options={allInvestors.map(inv => ({
                                    value: inv.uid,
                                    label: `${inv.firstName} ${inv.lastName}`
                                  }))}
                                />
                                <NumberInput name="numShares" label="Shares" />
                                <IonButton
                                  onClick={submitForm}
                                  disabled={!investable}
                                >
                                  {investment.status === "closed"
                                    ? "Add to waiting list"
                                    : "Reserve"}
                                </IonButton>
                              </div>
                            )}
                          </Formik>
                        </IonItem>
                      </IonList>
                    </>
                  )}
                />
                <p className="section-heading">Transactions</p>
                <p className="px-4">
                  Total number of shares allocated:
                  {transactions.reduce((curr, t) => curr + t.numShares, 0)}
                </p>
                <IonList>
                  {transactions.map(t => {
                    const user = getUser(t.user);
                    return (
                      <IonItem
                        key={t.id}
                        href={`investments/${investment.id}/transactions/${t.id}`}
                      >
                        {user?.firstName} {user?.lastName}
                        <IonNote slot="end">{t.numShares} shares</IonNote>
                      </IonItem>
                    );
                  })}
                  <IonItem>
                    <Formik
                      initialValues={{
                        ...emptyTransaction,
                        investment: investment.id
                      }}
                      onSubmit={(newTransaction, { resetForm }) => {
                        if (newTransaction.user === "") return;
                        setTransactionsToAdd([
                          ...transactionsToAdd,
                          newTransaction
                        ]);
                        resetForm();
                      }}
                    >
                      {({ submitForm }) => (
                        <div className="flex w-full items-center justify-between">
                          <SelectInput
                            label="User"
                            name="user"
                            options={allInvestors.map(inv => ({
                              value: inv.uid,
                              label: `${inv.firstName} ${inv.lastName}`
                            }))}
                          />
                          <NumberInput name="numShares" label="Amount" />
                          <IonButton
                            onClick={submitForm}
                            disabled={!investable}
                          >
                            Add
                          </IonButton>
                        </div>
                      )}
                    </Formik>
                  </IonItem>
                  <div className="text-primary text-lg px-4">
                    The following transtions will be added on save
                  </div>
                  {transactionsToAdd.map((t, index) => {
                    const user = getUser(t.user);
                    return (
                      <IonItem key={`to-add-${index}`}>
                        {user?.firstName} {user?.lastName} {t.numShares} shares
                        <IonNote slot="end">
                          <IonIcon
                            icon={trashOutline}
                            className="text-danger text-lg"
                            onClick={() =>
                              setTransactionsToAdd(
                                transactionsToAdd.filter((_, i) => i !== index)
                              )
                            }
                          />
                        </IonNote>
                      </IonItem>
                    );
                  })}
                </IonList>
                <div className="text-center mt-10">
                  <IonButton
                    disabled={
                      investment.status !== "draft" ||
                      investment.assets.length > 0
                    }
                    color="danger"
                    onClick={() =>
                      presentAlert({
                        header: "Delete Investment",
                        subHeader: investment.name,
                        message:
                          "Are you sure you want to delete this investment?",
                        buttons: [
                          { text: "Cancel", role: "cancel" },
                          {
                            text: "Delete",
                            handler: () => {
                              deleteInvestment(investment.id).then(() =>
                                history.goBack()
                              );
                            }
                          }
                        ]
                      })
                    }
                  >
                    <IonIcon icon={trashOutline} className="mr-2" />
                    Delete Investment
                  </IonButton>
                </div>
              </div>
              <div className="fixed bottom-0 w-full py-2 bg-white z-10">
                <div className="px-2 flex justify-between">
                  <IonButton color="danger" onClick={() => history.goBack()}>
                    Cancel
                  </IonButton>
                  <IonButton
                    type="submit"
                    color="success"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? <IonSpinner /> : "Save"}
                  </IonButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export default SingleInvestment;

const CalculateValues: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<Investment>();
  const assets = values?.assets;
  const numberOfShares = values?.totalShares;

  const getAsset = useAssetsStore(state => state.getAsset);

  useEffect(() => {
    const totalValue = assets.reduce(
      (total, a) => total + (getAsset(a)?.value || 0),
      0
    );
    const unitValue = totalValue / numberOfShares;
    setFieldValue("totalValue", totalValue);
    setFieldValue("shareValue", unitValue);
    //TODO: add admin fee
  }, [assets, numberOfShares, getAsset, setFieldValue]);
  return (
    <div className="text-lg m-2 w-1/2 ml-auto right-0 ">
      <div className="flex justify-between">
        <div>Total value:</div>
        <div>{toCurrency(values.totalValue)}</div>
      </div>
      <div className="flex justify-between">
        <div>Value of 1 share:</div>
        <div>{toCurrency(values.shareValue)}</div>
      </div>
    </div>
  );
};

import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonItem,
  IonList,
  IonModal
} from "@ionic/react";
import { ReactNode, useRef, useState } from "react";

interface MultipleSelectModalProps {
  addValue: (id: string) => void;
  values: any[];
  trigger: string;
  heading: string;
  accessor?: string;
  render: (el: any) => ReactNode;
}

const MultipleSelectModal: React.FC<MultipleSelectModalProps> = ({
  addValue,
  values,
  trigger,
  heading,
  accessor = "id",
  render
}) => {
  const modal = useRef<HTMLIonModalElement>(null);

  const [selected, setSelected] = useState<string[]>([]);

  const onCheck = (checked: boolean, id: string) => {
    let tempSelected = selected;
    if (checked) {
      tempSelected.push(id);
    } else {
      tempSelected.splice(tempSelected.indexOf(id), 1);
    }
    setSelected([...tempSelected]);
  };
  return (
    <IonModal
      trigger={trigger}
      ref={modal}
      onWillPresent={() => setSelected([])}
    >
      <IonContent>
        <p className="text-primary text-xl m-4">{heading}</p>
        <IonList>
          {values.length === 0 ? (
            <p className="text-center text-gray-400">No options available</p>
          ) : (
            values.map(p => (
              <IonItem key={p[accessor]}>
                <div className="flex justify-between w-full items-center">
                  {render(p)}
                  <IonCheckbox
                    onIonChange={e => onCheck(e.detail.checked, p[accessor])}
                  />
                </div>
              </IonItem>
            ))
          )}
        </IonList>
      </IonContent>
      <IonFooter>
        <div className="flex justify-between m-2">
          <IonButton onClick={() => modal.current?.dismiss()} color="danger">
            Cancel
          </IonButton>
          <IonButton
            onClick={() => {
              selected.forEach(s => addValue(s));
              modal.current?.dismiss();
            }}
            color="success"
          >
            Add
          </IonButton>
        </div>
      </IonFooter>
    </IonModal>
  );
};

export default MultipleSelectModal;

import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonSpinner,
  IonToolbar,
  useIonAlert,
  useIonModal
} from "@ionic/react";
import { deleteObject, getBlob, ref, uploadBytes } from "firebase/storage";
import { useField } from "formik";
import {
  addOutline,
  documentOutline,
  documentTextOutline,
  imageOutline,
  trashOutline,
  createOutline
} from "ionicons/icons";
import { useRef, useState } from "react";
import { storage } from "../../lib/firebase";
import { UserFile } from "../../types/userFile.type";
import { FileModal } from "../FileModal";
import TextInput from "./TextInput";
import toast from "react-hot-toast";

interface InputProps {
  label: string;
  name: string;
  filePath: string;
  singleFile?: boolean;
  fileTypes?: string[];
  disabled?: boolean;
}

const FileInput: React.FC<InputProps> = ({ label, disabled, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props.name);
  const fileInput = useRef(null);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UserFile>();
  const [presentAlert] = useIonAlert();
  const [editFileIsOpen, setEditFileIsOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState<any>();
  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const handleDismiss = () => {
    dismiss();
  };

  if (!props.fileTypes) {
    props.fileTypes = [
      "application/msword",
      "application/vnd.ms-excel",
      "application/vnd.ms-powerpoint",
      "text/plain",
      "application/pdf",
      "image/*"
    ];
  }

  const [present, dismiss] = useIonModal(FileModal, {
    file: currentFile,
    fileObject: file,
    onDismiss: handleDismiss
  });

  const editFile = (index: number) => {
    setCurrentFileIndex(index);
    setEditFileIsOpen(true);
  };
  const displayFile = async (file: UserFile) => {
    setCurrentFile(undefined);
    setFile(file);
    present();
    const storageRef = ref(storage, file.path);
    const blob = await getBlob(storageRef);
    setCurrentFile(blob);
  };

  const onSelectFile = async (e: any) => {
    const file = e.target.files[0];

    if (file) {
      // const filePath = `properties/${[props.id]}/` + file.name;
      const filePath = props.filePath + "/" + file.name;

      const storageRef = ref(storage, filePath);
      const promise = uploadBytes(storageRef, file);
      toast.promise(promise, {
        loading: "Uploading...",
        success: "File uploaded",
        error: "Upload failed"
      });
      const snapshot = await promise;
      field.value.push({
        path: snapshot.metadata.fullPath,
        name: "",
        description: ""
      });
      helpers.setValue(field.value);
    }
  };

  const handleFocusBack = () => {
    setLoading(false);
    window.removeEventListener("focus", handleFocusBack);
  };
  return (
    <>
      <IonList>
        <IonListHeader>
          <IonLabel color="primary">{label}</IonLabel>
          <div>
            <input
              ref={fileInput}
              hidden
              name={props.name}
              type="file"
              accept={props.fileTypes.join(",")}
              onChange={onSelectFile}
              onClick={() => {
                setLoading(true);
                window.addEventListener("focus", handleFocusBack);
              }}
            />
            <IonButton
              color="primary"
              className="mx-3"
              fill="outline"
              onClick={() => {
                // @ts-ignore-next-line
                fileInput?.current?.click();
              }}
              disabled={disabled}
            >
              {loading ? <IonSpinner /> : <IonIcon icon={addOutline} />}
            </IonButton>
          </div>
        </IonListHeader>

        {field.value?.map((file: UserFile, index: number) => {
          const filepath = file.path;
          const fileExtention = filepath.split(".").pop();
          const fileIcon = () => {
            switch (fileExtention) {
              case "pdf":
                return documentTextOutline;
              case "png":
                return imageOutline;
              case "jpg":
                return imageOutline;
              default:
                return documentOutline;
            }
          };

          return (
            <IonItem button key={filepath} onClick={() => displayFile(file)}>
              <IonIcon slot="start" icon={fileIcon()} />
              <div>
                <IonLabel>{file.name}</IonLabel>
                <div className="text-sm text-gray-500">{file.description}</div>
              </div>
              <IonButton
                slot="end"
                fill="outline"
                onClick={e => {
                  e.stopPropagation();
                  editFile(index);
                }}
                disabled={disabled}
              >
                <IonIcon icon={createOutline} />
              </IonButton>
              <IonButton
                color="danger"
                slot="end"
                fill="outline"
                disabled={disabled}
                onClick={e => {
                  e.stopPropagation();
                  presentAlert({
                    header: "Delete File",
                    message: "Are you sure you want to delete this file?",
                    buttons: ["Cancel", "Delete"],
                    onDidDismiss: async (e: any) => {
                      if (e.detail.role !== "cancel") {
                        field.value = field.value.filter(
                          (fileObj: any) => fileObj.path !== filepath
                        );
                        helpers.setValue(field.value);
                        await deleteObject(ref(storage, filepath));
                      }
                    }
                  });
                }}
              >
                <IonIcon icon={trashOutline} />
              </IonButton>
            </IonItem>
          );
        })}
      </IonList>
      <IonModal
        isOpen={editFileIsOpen}
        onWillDismiss={() => setEditFileIsOpen(false)}
      >
        <IonContent className="ion-padding">
          <TextInput
            label="Name:"
            name={`${props.name}[${currentFileIndex}].name`}
            placeholder="Example"
          />
          <TextInput
            label="Description:"
            name={`${props.name}[${currentFileIndex}].description`}
            placeholder="Example"
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton slot="end" onClick={() => setEditFileIsOpen(false)}>
              Done
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default FileInput;

import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc
} from "firebase/firestore";
import create from "zustand";
import { db } from "../lib/firebase";
import { Investment } from "../types/investment.type";

interface InvestmentState {
  fetching: boolean;
  investments: Investment[];
  updateInvestment: (
    id: string,
    fields: { [index: string]: any }
  ) => Promise<void>;
  deleteInvestment: (id: string) => Promise<void>;
}

export const useInvestmentsStore = create<InvestmentState>((set, get) => ({
  investments: [],
  fetching: false,
  updateInvestment: async (id: string, fields: { [index: string]: any }) => {
    fields.availableShares = fields.totalShares - fields.reservedShares;
    set({ fetching: true });
    const ref = doc(db, "investments", id);
    await updateDoc(ref, fields);
    set({ fetching: false });
  },
  deleteInvestment: async (id: string) => {
    const ref = doc(db, "investments", id);
    await deleteDoc(ref);
  }
}));

onSnapshot(query(collection(db, "investments")), querySnapshot => {
  useInvestmentsStore.setState({ fetching: true });
  let tempInvestment: Investment[] = [];

  querySnapshot.forEach(doc => {
    tempInvestment.push(toInvestment(doc));
  });
  useInvestmentsStore.setState({
    investments: tempInvestment,
    fetching: false
  });
});

function toInvestment(doc: any): Investment {
  let investment: any = { ...doc.data(), id: doc.id };
  investment.opensAt = investment.opensAt.toDate();
  investment.closesAt = investment.closesAt.toDate();

  investment.reserved = investment.reserved.map((r: any) => {
    return { ...r, timestamp: r.timestamp.toDate() };
  });

  return investment as Investment;
}

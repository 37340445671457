import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { signOut } from "firebase/auth";
import { ReactNode } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { auth } from "../lib/firebase";
import { useAuthStore } from "../stores/authStore";

interface LayoutProps {
  children?: React.ReactNode;
  title?: string;
  showToolbar?: boolean;
  footer?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  title = "",
  showToolbar = true,
  footer
}) => {
  const user = useAuthStore(state => state.user);
  const history = useHistory();
  const logout = () => {
    signOut(auth).then(() => {
      toast.success("User logged out");
      history.replace("/login");
    });
  };

  return (
    <IonPage>
      {showToolbar && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton menu="side" autoHide={true} />
            </IonButtons>

            <IonTitle className="text-primary">{title}</IonTitle>
            <IonTitle className="text-primary" slot="end">
              {user?.email}
            </IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={logout} className="text-primary">
                Log out
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen>{children}</IonContent>
    </IonPage>
  );
};

export default Layout;

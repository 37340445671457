import { IonButton, IonIcon } from "@ionic/react";
import { cartOutline, homeOutline, peopleCircle } from "ionicons/icons";
import Layout from "../components/Layout";

const Home: React.FC = () => {
  return (
    <Layout title="Home">
      <div className="flex justify-center">
        <IonButton routerLink="/investors" className="w-32 h-32 m-8">
          <div className="grid place-items-center">
            <IonIcon className="text-6xl py-2" icon={peopleCircle} />
            <div>Investors</div>
          </div>
        </IonButton>
        <IonButton routerLink="/assets" className="w-32 h-32 m-8">
          <div className="grid place-items-center">
            <IonIcon className="text-6xl py-2" icon={homeOutline} />
            <div>Assets</div>
          </div>
        </IonButton>
        <IonButton routerLink="/investments" className="w-32 h-32 m-8">
          <div className="grid place-items-center">
            <IonIcon className="text-6xl py-2" icon={cartOutline} />
            <div>Investments</div>
          </div>
        </IonButton>
      </div>
    </Layout>
  );
};

export default Home;

import { UserFile } from "./userFile.type";

export interface Asset {
  id: string;
  name: string;
  address: string;
  city: string;
  area: string;
  country: string;
  postalcode: string;
  value: number;
  totalAssetValue: number;
  description: string;
  purchaseTerms: string;
  classAdd: string;
  squareMeters: number;
  yearBuilt: number;
  features: string[];
  documents: UserFile[];
  images: UserFile[];
  owner: string;
  createdAt: Date;
  updatedAt: Date;
  streetViewUrl: string;
  type: "property";
  investment: string;
}

export const EmptyAsset = {
  name: "",
  address: "",
  city: "",
  area: "",
  country: "",
  postalcode: "",
  value: 0,
  totalAssetValue: 0,
  description: "",
  purchaseTerms: "",
  classAdd: "",
  sqaureMeters: 0,
  yearBuilt: 0,
  features: [],
  documents: [],
  images: [],
  owner: "",
  type: "property",
  createdAt: new Date(),
  updatedAt: new Date(),
  investment: ""
};

import Home from "./pages/Home";
import Login from "./pages/Login";
import InvestorManagement from "./pages/InvestorManagement";
import SingleInvestor from "./pages/InvestorSingle";
import SingleAsset from "./pages/AssetSingle";
import AssetManagement from "./pages/AssetManagement";
import InvestmentManagement from "./pages/InvestmentManagement";
import SingleInvestment from "./pages/InvestmentSingle";
import Transaction from "./pages/Transaction";

export const routes = [
  {
    name: "Home",
    component: Home,
    path: "/"
  },
  { name: "Login", component: Login, path: "/login" },
  {
    name: "Investor Management",
    component: InvestorManagement,
    path: "/investors"
  },
  {
    name: "Assets",
    component: AssetManagement,
    path: "/assets"
  },
  {
    name: "Single Investor",
    component: SingleInvestor,
    path: "/investors/:uid"
  },
  {
    name: "Single Asset",
    component: SingleAsset,
    path: "/assets/:id"
  },
  {
    name: "Investments",
    component: InvestmentManagement,
    path: "/investments"
  },
  {
    name: "Single Investment",
    component: SingleInvestment,
    path: "/investments/:id"
  },
  {
    name: "Investor on investment",
    component: Transaction,
    path: "/investments/:investmentId/transactions/:transactionId"
  }
];

import { IonInput, IonItem, IonLabel, IonText } from "@ionic/react";
import { useField } from "formik";

interface InputProps {
  label: string;
  name: string;
  disabled?: boolean;
}
const NumberInput: React.FC<InputProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <IonItem>
        <IonLabel position="floating">{label}</IonLabel>
        <IonInput
          {...props}
          onIonChange={field.onChange}
          value={field.value}
          autoCorrect="on"
          onBlur={field.onBlur}
          type="number"
          disabled={props.disabled}
        />
      </IonItem>
      <IonText className="text-danger text-sm">
        {meta.touched && meta.error}
      </IonText>
    </>
  );
};

export default NumberInput;

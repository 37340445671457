import { IonButton, IonSpinner } from "@ionic/react";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import Layout from "../components/Layout";
import CheckInput from "../components/forms/CheckInput";
import FileInput from "../components/forms/FileInput";
import { useInvestmentsStore } from "../stores/investmentsStore";
import { useInvestorsStore } from "../stores/investorsStore";
import { useTransactionsStore } from "../stores/transactionsStore";

const Transaction: React.FC = () => {
  let { investmentId, transactionId } = useParams<{
    investmentId: string;
    transactionId: string;
  }>();
  const investment = useInvestmentsStore(state =>
    state.investments.find(i => i.id === investmentId)
  );
  const transaction = useTransactionsStore(state =>
    state.transactions.find(t => t.id === transactionId)
  );
  const investor = useInvestorsStore(state =>
    state.investors.find(i => i.uid === transaction?.user)
  );
  const updateTransaction = useTransactionsStore(
    state => state.updateTransaction
  );
  const history = useHistory();
  if (!investment || !transaction || !investor) {
    return <div>Not found</div>;
  }
  return (
    <Layout showToolbar={false}>
      <Formik
        initialValues={{ ...transaction }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          updateTransaction(transactionId, values).then(() => {
            setSubmitting(false);
            history.push(`/investments/${investmentId}`);
            toast.success("Saved");
          });
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <div className="mx-4">
              <div className="text-xl font-bold text-primary my-4">
                {investor.firstName} {investor.lastName}'s investment on{" "}
                {investment.name}
              </div>
              <p className="section-heading">Info</p>
              <div>Ref number: {transaction.id}</div>
              <div>Number of shares: {transaction.numShares}</div>
              <p className="section-heading">Status</p>
              <div>
                <CheckInput
                  name="status.documentation.complete"
                  label="Documentation complete"
                />
                <div>
                  Updated at:{" "}
                  {transaction.status.documentation.updated.toString()}
                </div>
                <CheckInput
                  name="status.payment.complete"
                  label="Payment complete"
                />
                <div>
                  Updated at:{" "}
                  {transaction.status.documentation.updated.toString()}
                </div>
              </div>
              <p className="section-heading">Documents</p>
              <FileInput
                name="documents"
                label="Documents"
                filePath={`investments/${investmentId}/${transactionId}`}
                fileTypes={["image/*"]}
              />
            </div>
            <div className="fixed bottom-0 w-full py-2 bg-white z-10">
              <div className="px-2 flex justify-between">
                <IonButton color="danger" onClick={() => history.goBack()}>
                  Cancel
                </IonButton>
                <IonButton
                  type="submit"
                  color="success"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? <IonSpinner /> : "Save"}
                </IonButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};
export default Transaction;

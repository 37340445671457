import { IonButton, IonItem, IonList, IonNote, IonSpinner } from "@ionic/react";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import CheckInput from "../components/forms/CheckInput";
import FileInput from "../components/forms/FileInput";
import { getUserValidationSchema } from "../components/forms/getValidationSchema";
import SelectInput from "../components/forms/SelectInput";
import TextInput from "../components/forms/TextInput";
import validate from "../components/forms/validate";
import Layout from "../components/Layout";
import { useInvestorsStore } from "../stores/investorsStore";
import { User } from "../types/user.type";
import { useInvestmentsStore } from "../stores/investmentsStore";
import { useTransactionsStore } from "../stores/transactionsStore";

const SingleInvestor: React.FC = () => {
  const history = useHistory();
  let { uid } = useParams<{ uid: string }>();
  const [investor, updateInvestor] = useInvestorsStore(state => [
    state.investors.find(u => u.uid === uid),
    state.updateInvestor
  ]);

  const allInvestments = useInvestmentsStore(state => state.investments);

  const reservedInvestments = useInvestmentsStore(state =>
    state.investments.filter(i => i.reserved.some(r => r.user === uid))
  );
  const transactions = useTransactionsStore(state =>
    state.transactions.filter(t => t.user === uid)
  );
  return (
    <Layout showToolbar={false}>
      <h1 className="text-center text-xl p-2 text-primary">Edit investor</h1>
      {!investor ? (
        <div className="text-center">
          <IonSpinner color="primary" />
        </div>
      ) : (
        <Formik
          initialValues={{ ...investor }}
          onSubmit={(values, { setSubmitting }) => {
            updateInvestor(uid, values).then(() => {
              setSubmitting(false);
              toast.success("Investor updated");
              history.goBack();
            });
          }}
          validate={validate(getUserValidationSchema)}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => {
            return (
              <Form>
                <div className="p-2 pb-20 max-w-3xl mx-auto">
                  <div className="section-heading">Basic information</div>
                  <TextInput
                    name="email"
                    label="Email:"
                    placeholder="Enter email"
                    type="email"
                  />
                  <TextInput
                    name="firstName"
                    label="First name:"
                    placeholder="Enter first name"
                  />
                  <TextInput
                    name="lastName"
                    label="Last name:"
                    placeholder="Enter last name"
                  />
                  <TextInput
                    name="phoneNumber"
                    label="Phone Number"
                    placeholder="000 000 0000"
                    type="tel"
                  />
                  <p className="section-heading">Additional information</p>
                  <SelectInput
                    name="verificationStatus"
                    label="Verification Status:"
                    options={[
                      { label: "Not verified", value: "" },
                      { label: "Pending", value: "pending" },
                      { label: "Verified", value: "verified" }
                    ]}
                  />
                  <SelectInput
                    name="entityType"
                    label="Entity type:"
                    options={[
                      { label: "Individual", value: "individual" },
                      { label: "Company", value: "company" }
                    ]}
                  />
                  {values.entityType === "company" && (
                    <>
                      <TextInput
                        name="companyName"
                        label="Company name:"
                        placeholder="Enter company name"
                      />
                      <TextInput
                        name="registrationNumber"
                        label="Company registration number:"
                        placeholder="Enter company registration number"
                      />
                      <CheckInput
                        name="sameRepresentative"
                        label="The representative of this company is the same as the account holder"
                      />
                      {!values.sameRepresentative && (
                        <>
                          <TextInput
                            name="representative.firstName"
                            label="First name:"
                            placeholder="Enter first name"
                          />
                          <TextInput
                            name="representative.lastName"
                            label="Last name:"
                            placeholder="Enter last name"
                          />
                          <TextInput
                            name="representative.phoneNumber"
                            label="Phone Number"
                            placeholder="000 000 0000"
                            type="tel"
                          />
                        </>
                      )}
                    </>
                  )}
                  <TextInput
                    name="idNumber"
                    label="ID number"
                    placeholder="0101010101010"
                  />
                  <TextInput
                    name="address"
                    label="Address"
                    placeholder="01 example street"
                  />
                  <FileInput
                    name="files"
                    label="Files"
                    filePath={`users/${investor.uid}/`}
                  />
                  <div>
                    <p className="section-heading">Verification</p>
                    <CheckInput
                      name="verification.information"
                      label="Information verified"
                    />
                    {values.entityType === "individual" ? (
                      <CheckInput name="verification.id" label="ID verified" />
                    ) : (
                      <>
                        <CheckInput
                          name="verification.certification"
                          label="Certification verified"
                        />
                        <CheckInput
                          name="verification.directors"
                          label="Directors verified"
                        />
                        <CheckInput
                          name="verification.cipc"
                          label="CIPC verified"
                        />
                      </>
                    )}
                    <UpdateVerificationStatus />
                  </div>
                  <div>
                    <p className="section-heading mb-4">Investments</p>
                    <div className="ml-4">
                      <p className="font-bold text-lg text-primary">
                        Reserved investments:
                      </p>
                      <IonList>
                        {reservedInvestments.map(i => (
                          <IonItem key={i.id}>
                            {i.name}
                            <IonNote slot="end">
                              {i.reserved.find(r => r.user === uid)?.numShares}{" "}
                              shares
                            </IonNote>
                            {i.reserved.find(r => r.user === uid)?.waitList && (
                              <IonNote slot="end" color="danger">
                                Waitlist
                              </IonNote>
                            )}
                          </IonItem>
                        ))}
                      </IonList>
                    </div>
                    <div className="ml-4">
                      <p className="font-bold text-lg text-primary">
                        Transactions:
                      </p>
                      <IonList>
                        {transactions.map(i => {
                          const investment = allInvestments.find(
                            inv => inv.id === i.investment
                          );
                          if (!investment) return null;
                          return (
                            <IonItem key={investment.id}>
                              {investment.name}
                              <IonNote slot="end">{i.numShares} shares</IonNote>
                            </IonItem>
                          );
                        })}
                      </IonList>
                    </div>
                  </div>
                </div>
                <div className="fixed bottom-0 w-full py-2 bg-white z-10">
                  <div className="px-2 flex justify-between">
                    <IonButton color="danger" onClick={() => history.goBack()}>
                      Cancel
                    </IonButton>
                    <IonButton
                      type="submit"
                      color="success"
                      disabled={isSubmitting || !isValid}
                    >
                      {isSubmitting ? <IonSpinner /> : "Save"}
                    </IonButton>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </Layout>
  );
};

const UpdateVerificationStatus: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<User>();
  useEffect(() => {
    let verificationStatus = "";
    if (values.entityType === "individual" && values.verificationStatus) {
      verificationStatus =
        values.verification?.information && values.verification.id
          ? "verified"
          : "pending";
    } else if (values.entityType === "company") {
      verificationStatus =
        values.verification?.information &&
        values.verification.certification &&
        values.verification.directors &&
        values.verification.cipc
          ? "verified"
          : "pending";
    }
    setFieldValue("verificationStatus", verificationStatus);
    // eslint-disable-next-line
  }, [values.verification, setFieldValue, values.entityType]);
  return null;
};

export default SingleInvestor;

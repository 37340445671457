import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner
} from "@ionic/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import { auth } from "../lib/firebase";

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const login = async () => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const idTokenResult = await user.getIdTokenResult();
      if (!!idTokenResult?.claims?.admin) {
        toast.success("User logged in");
        history.replace("/");
      } else {
        throw new Error();
      }
    } catch {
      toast.error("Wrong email or password");
    }
    setLoading(false);
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="max-w-lg mx-auto pt-20">
          <h1 className="text-center text-primary text-5xl p-5">Login</h1>

          <IonList>
            <IonItem>
              <IonLabel position="stacked">Email</IonLabel>
              <IonInput
                value={email}
                type="email"
                onIonChange={e => setEmail(e.detail.value!)}
              />
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                value={password}
                type="password"
                onIonChange={e => setPassword(e.detail.value!)}
              />
            </IonItem>
          </IonList>
          <IonButton
            fill="solid"
            expand="full"
            className="mt-5"
            onClick={login}
            disabled={loading}
          >
            {loading ? <IonSpinner /> : "Login"}
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;

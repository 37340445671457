import { IonButton, IonSpinner } from "@ionic/react";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";
import Layout from "../components/Layout";
import Table, { Column } from "../components/Table";
import { db } from "../lib/firebase";
import { toCurrency } from "../lib/utils";
import { useInvestmentsStore } from "../stores/investmentsStore";
import { EmptyInvestment } from "../types/investment.type";

const columns: Column[] = [
  {
    accessor: "name",
    label: "Name:",
    type: "string"
  },
  {
    accessor: "totalUnits",
    label: "Number of units:",
    type: "string"
  },
  {
    accessor: "totalValue",
    label: "Value:",
    type: "number",
    formatter: val => toCurrency(val)
  },
  {
    accessor: "status",
    label: "Status",
    type: "option"
  }
];

const InvestmentManagement: React.FC = () => {
  const [investments, fetching] = useInvestmentsStore(state => [
    state.investments,
    state.fetching
  ]);
  const history = useHistory();
  const [creating, setCreating] = useState(false);

  const addInvestment = async () => {
    setCreating(true);
    const newDoc = await addDoc(collection(db, "investments"), EmptyInvestment);
    const id = newDoc.id;

    toast.success("Investment added");
    setCreating(false);
    history.push(`/investments/${id}`);
  };
  return (
    <Layout title="Investment Management">
      <div className="flex items-center justify-end px-2 abs">
        <IonButton fill="outline" disabled={creating} onClick={addInvestment}>
          {creating ? <IonSpinner /> : "Add Investment"}
        </IonButton>
      </div>
      <Table
        allData={investments}
        loading={fetching}
        columns={columns}
        onRowClick={investment => history.push(`/investments/${investment.id}`)}
      />
    </Layout>
  );
};

export default InvestmentManagement;

import {
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonSpinner
} from "@ionic/react";
import { useEffect, useState } from "react";
import { caretDown, caretUp } from "ionicons/icons";

export interface Column {
  accessor: string;
  label: string;
  type: "option" | "string" | "number";
  formatter?: (val: any) => any;
}

interface TableProps {
  loading: boolean;
  columns: Column[];
  allData: any[];
  onRowClick?: (d: any) => void;
}

const Table: React.FC<TableProps> = ({
  loading,
  columns,
  allData,
  onRowClick = () => {}
}) => {
  const [data, setData] = useState(allData);
  const [sortBy, setSortBy] = useState<{
    key: string;
    order: "ASC" | "DESC";
  }>({ key: "", order: "ASC" });
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  useEffect(() => {
    if (!sortBy) return;
    let tempData = [...allData];
    const sortByType = columns.find(c => c.accessor === sortBy.key)?.type;
    if (sortByType === "string") {
      tempData.sort((a, b) => {
        let item1 = a[sortBy.key]?.toLowerCase();
        let item2 = b[sortBy.key]?.toLowerCase();

        if (item1 < item2) {
          return -1;
        }
        if (item1 > item2) {
          return 1;
        }
        return 0;
      });
    } else if (sortByType === "number") {
      tempData.sort();
    }
    if (sortBy.order === "DESC") tempData.reverse();
    Object.keys(filters).forEach(key => {
      const filterType = columns.find(c => c.accessor === key)?.type;
      tempData = tempData.filter(d => {
        if (filterType === "string") {
          return d[key].indexOf(filters[key]) !== -1;
        }
        if (filterType === "option" && filters[key] !== null) {
          return d[key] === filters[key];
        }
        return true;
      });
    });

    setData(tempData);
  }, [sortBy, allData, filters, columns]);

  if (loading)
    return (
      <div className="text-center">
        <IonSpinner color="primary" />
      </div>
    );
  return (
    <div className="p-2">
      <table className="table-auto w-full">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <td key={index} className="text-gray-500 text-sm">
                {col.type === "string" && (
                  <IonInput
                    value={filters[col.accessor]}
                    onIonChange={e =>
                      setFilters(f => ({
                        ...f,
                        [col.accessor]: e.detail.value!
                      }))
                    }
                    className="border border-gray-400 w-20 text-xs rounded"
                    class="no-padding"
                  />
                )}
                {col.type === "option" && (
                  <IonSelect
                    value={filters[col.accessor] || null}
                    onIonChange={e =>
                      setFilters(f => ({
                        ...f,
                        [col.accessor]: e.detail.value!
                      }))
                    }
                  >
                    <IonSelectOption value={null}>No filter</IonSelectOption>
                    {allData
                      .map(d => d[col.accessor])
                      .filter(
                        (v, i, a) => v !== undefined && a.indexOf(v) === i
                      )
                      .map(d => (
                        <IonSelectOption value={d} key={d}>
                          {d}
                        </IonSelectOption>
                      ))}
                  </IonSelect>
                )}
                {col.label}
                <IonIcon
                  icon={sortBy.order === "ASC" ? caretUp : caretDown}
                  className={
                    sortBy.key === col.accessor
                      ? "text-primary"
                      : "text-gray-400"
                  }
                  onClick={() =>
                    setSortBy({
                      key: col.accessor,
                      order: sortBy.order === "ASC" ? "DESC" : "ASC"
                    })
                  }
                />
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y-2">
          {data.map((d, index) => (
            <tr
              key={index}
              onClick={() => onRowClick(d)}
              className="hover:text-primary hover:bg-gray-100"
            >
              {columns.map((col, index) => (
                <td key={index} className="py-2">
                  {col.formatter
                    ? col.formatter(d[col.accessor])
                    : d[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Table;

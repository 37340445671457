import { IonButton, IonIcon, IonSpinner, useIonAlert } from "@ionic/react";
import { Form, Formik } from "formik";
import toast from "react-hot-toast";
import { useHistory, useParams } from "react-router";
import { getAssetValidationSchema } from "../components/forms/getValidationSchema";
import SelectInput from "../components/forms/SelectInput";
import TextInput from "../components/forms/TextInput";
import validate from "../components/forms/validate";
import Layout from "../components/Layout";
import { useAssetsStore } from "../stores/assetsStore";
import FileInput from "../components/forms/FileInput";
import ListInput from "../components/forms/ListInput";
import { trashOutline } from "ionicons/icons";
import { toWords } from "number-to-words";
import { useInvestmentsStore } from "../stores/investmentsStore";

const SingleAsset: React.FC = () => {
  let { id } = useParams<{ id: string }>();
  const [asset, updateAsset, deleteAsset] = useAssetsStore(state => [
    state.assets.find(u => u.id === id),
    state.updateAsset,
    state.deleteAsset
  ]);
  const investment = useInvestmentsStore(state =>
    state.investments.find(i => i.id === asset?.investment)
  );
  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const editable = investment?.status === "draft" || !investment?.status;
  return (
    <Layout showToolbar={false}>
      <h1 className="text-xl p-5">{asset?.name}</h1>
      {!asset ? (
        <div className="text-center">
          <IonSpinner color="primary" />
        </div>
      ) : (
        <Formik
          initialValues={{ ...asset }}
          onSubmit={(values, { setSubmitting }) => {
            values.updatedAt = new Date();

            updateAsset(id, values).then(() => {
              setSubmitting(false);
              history.goBack();
              toast.success("Asset saved");
            });
          }}
          validate={validate(getAssetValidationSchema)}
        >
          {({ isSubmitting, isValid, values }) => (
            <Form>
              <div className="p-2 pb-20 max-w-3xl mx-auto">
                <TextInput
                  disabled={!editable}
                  name="name"
                  label="Name:"
                  placeholder="Name"
                />
                <TextInput
                  disabled={!editable}
                  name="address"
                  label="Address:"
                  placeholder="Address"
                  textArea={true}
                />
                <TextInput
                  disabled={!editable}
                  name="city"
                  label="City:"
                  placeholder="City"
                />
                <TextInput
                  disabled={!editable}
                  name="area"
                  label="Area:"
                  placeholder="Area"
                />
                <TextInput
                  disabled={!editable}
                  name="postalcode"
                  label="Postal Code:"
                  placeholder="Postal Code"
                />
                <TextInput
                  disabled={!editable}
                  name="streetViewUrl"
                  label="Street View URL:"
                  placeholder="https://www.google.com/maps/place/26%C2%B042'02.0%22S+27%C2%B049'27.1%22E/@-26.700554,27.824186,17z/data=!3m1!4b1!4m4!3m3!8m2!3d-26.700554!4d27.824186"
                />
                <SelectInput
                  name="country"
                  label="Country:"
                  options={[{ label: "RSA", value: "RSA" }]}
                  disabled={!editable}
                />
                <TextInput
                  disabled={!editable}
                  name="value"
                  label="Value:"
                  type="currency"
                  placeholder="Value"
                />
                <div className="text-center">{`${toWords(
                  values.value || 0
                )} Rand`}</div>
                <TextInput
                  disabled={!editable}
                  name="totalAssetValue"
                  label="Total Asset Value:"
                  type="currency"
                  placeholder="Total Asset Value"
                />
                <div className="text-center">{`${toWords(
                  values.totalAssetValue || 0
                )} Rand`}</div>
                <TextInput
                  disabled={!editable}
                  name="description"
                  label="Description:"
                  placeholder="Description of the asset"
                  textArea={true}
                />
                <TextInput
                  disabled={!editable}
                  name="purchaseTerms"
                  label="Purchase Terms:"
                  placeholder="Specify the terms of purchase and related information"
                  textArea={true}
                />
                <TextInput
                  disabled={!editable}
                  name="classAdd"
                  label="Class Add:"
                  placeholder="Marketing advert for the asset"
                  textArea={true}
                />
                <TextInput
                  disabled={!editable}
                  name="squareMeters"
                  label="Square Meters:"
                  placeholder="Square meters"
                  type="number"
                />
                <TextInput
                  disabled={!editable}
                  name="yearBuilt"
                  label="Year Built:"
                  placeholder=""
                  type="date"
                />
                <TextInput
                  disabled={!editable}
                  name="owner"
                  label="Owner:"
                  placeholder="Owner's details"
                />
                <FileInput
                  name="documents"
                  label="Attachments"
                  filePath={`assets/${id}/`}
                  disabled={!editable}
                />
                <FileInput
                  name="images"
                  label="Images"
                  filePath={`assets/${id}/`}
                  fileTypes={["image/*"]}
                  disabled={!editable}
                />
                <ListInput
                  name="features"
                  label="Asset Features"
                  disabled={!editable}
                />
                <div className="text-center mt-10">
                  <IonButton
                    disabled={asset.investment ? true : false}
                    color="danger"
                    onClick={() =>
                      presentAlert({
                        header: "Delete Asset",
                        subHeader: asset.name,
                        message: "Are you sure you want to delete this asset?",
                        buttons: [
                          { text: "Cancel", role: "cancel" },
                          {
                            text: "Delete",
                            handler: () => {
                              deleteAsset(asset.id).then(() =>
                                history.goBack()
                              );
                            }
                          }
                        ]
                      })
                    }
                  >
                    <IonIcon icon={trashOutline} className="mr-2" />
                    Delete asset
                  </IonButton>
                </div>
              </div>
              <div className="fixed bottom-0 w-full py-2 bg-white z-10">
                <div className="px-2 flex justify-between">
                  <IonButton color="danger" onClick={() => history.goBack()}>
                    Cancel
                  </IonButton>
                  <IonButton
                    type="submit"
                    color="success"
                    disabled={isSubmitting || !isValid}
                  >
                    {isSubmitting ? <IonSpinner /> : "Save"}
                  </IonButton>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};

export default SingleAsset;

import {
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle
} from "@ionic/react";

const pages = [
  { name: "Home", link: "/" },
  { name: "Investors", link: "/investors" },
  { name: "Assets", link: "/assets" },
  { name: "Investments", link: "/investments" }
];

const SideMenu: React.FC = () => {
  return (
    <IonMenu contentId="main" menuId="side">
      <IonHeader></IonHeader>
      <IonContent>
        <IonList>
          {pages.map(p => (
            <IonMenuToggle menu="side" key={p.link}>
              <IonItem routerLink={p.link}>{p.name}</IonItem>
            </IonMenuToggle>
          ))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenu;

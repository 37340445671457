export const investmentStatuses = [
  "draft",
  "listed",
  "open",
  "closed",
  "cancelled",
  "active",
  "completed"
] as const;
export type InvestmentStatus = typeof investmentStatuses[number];

export interface Investment {
  id: string;
  name: string;
  description: string;
  assets: string[];
  reserved: {
    user: string;
    timestamp: any;
    numShares: number;
    waitList?: boolean;
  }[];
  images: string[];
  opensAt: Date;
  closesAt: Date;
  totalShares: number;
  reservedShares: number;
  availableShares: number;
  shareValue: number;
  paysAt: Date;
  createdAt: Date;
  updatedAt: Date;
  investmentTerms: string;
  documents?: any[];
  totalValue: number;
  yield: number;
  status: InvestmentStatus;
}

const oneMonthFromNow = new Date();
oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
const oneWeekFromNow = new Date();
oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

export const EmptyInvestment: Investment = {
  id: "",
  assets: [],
  reserved: [],
  images: [],
  closesAt: oneMonthFromNow,
  opensAt: oneWeekFromNow,
  totalShares: 1,
  reservedShares: 0,
  availableShares: 0,
  shareValue: 0,
  paysAt: new Date(),
  createdAt: new Date(),
  updatedAt: new Date(),
  investmentTerms: "",
  documents: [],
  yield: 0,
  totalValue: 0,
  name: "",
  description: "",
  status: "draft"
};

import {
  IonButton,
  IonHeader,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonSpinner,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { doc, setDoc } from "firebase/firestore";
import { Form, Formik } from "formik";
import { RefObject, useRef } from "react";
import { useHistory } from "react-router";
import TextInput from "../components/forms/TextInput";
import Layout from "../components/Layout";
import Table, { Column } from "../components/Table";
import { db } from "../lib/firebase";
import { useInvestorsStore } from "../stores/investorsStore";
import toast from "react-hot-toast";
import { getUserValidationSchema } from "../components/forms/getValidationSchema";
import validate from "../components/forms/validate";
import { useAuthStore } from "../stores/authStore";

const columns: Column[] = [
  { accessor: "phoneNumber", label: "Mobile Phone*", type: "string" },
  { accessor: "email", label: "Email", type: "string" },
  { accessor: "firstName", label: "First name", type: "string" },
  { accessor: "lastName", label: "Last name", type: "string" },
  {
    accessor: "verificationStatus",
    label: "Verification Status",
    type: "option"
  }
];

const InvestorManagement: React.FC = () => {
  const addInvestorModal = useRef<HTMLIonModalElement>(null);
  const whitelist = useAuthStore(state => state.whitelist);
  console.log(whitelist);
  const history = useHistory();
  const [investors, fetching] = useInvestorsStore(state => [
    state.investors,
    state.fetching
  ]);

  return (
    <Layout title="Investor Management">
      <AddInvestorModal
        modalRef={addInvestorModal}
        trigger="open-add-investor"
        dismiss={() => addInvestorModal.current?.dismiss()}
      />
      <div className="flex items-center justify-end px-2 abs">
        <IonButton fill="outline" id="open-add-investor">
          Access Control
        </IonButton>
      </div>
      <Table
        loading={fetching}
        columns={columns}
        allData={investors}
        onRowClick={investor => history.push(`/investors/${investor.uid}`)}
      />
    </Layout>
  );
};

export default InvestorManagement;

interface AddInvestorModalProps {
  modalRef: RefObject<HTMLIonModalElement>;
  trigger: string;
  dismiss: () => void;
}

const AddInvestorModal: React.FC<AddInvestorModalProps> = ({
  modalRef,
  trigger,
  dismiss
}) => {
  const whitelist = useAuthStore(state => state.whitelist);
  async function addInvestor(email: string, phoneNumber: string) {
    phoneNumber = phoneNumber.replace(/\D/g, "");
    const docRef = doc(db, "whitelist", phoneNumber);
    await setDoc(docRef, {
      email,
      phoneNumber
    });
  }

  return (
    <IonModal ref={modalRef} trigger={trigger}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Investor</IonTitle>
        </IonToolbar>
      </IonHeader>
      <Formik
        initialValues={{ email: "", phoneNumber: "" }}
        validate={validate(getUserValidationSchema)}
        onSubmit={(values, { setSubmitting }) => {
          addInvestor(values["email"], values["phoneNumber"])
            .then(() => {
              setSubmitting(false);
              dismiss();
              toast.success("Investor added");
            })
            .catch(e => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, errors, isValid }) => (
          <Form>
            <div className="p-2">
              <TextInput
                name="email"
                label="Email:"
                placeholder="example@example.com"
                type="email"
              />
              <TextInput
                name="phoneNumber"
                label="Phone:"
                placeholder="0000000000"
                type="tel"
              />
            </div>
            <div className="absolute bottom-0 w-full py-2">
              <div className="px-2 flex justify-between">
                <IonButton color="danger" onClick={dismiss}>
                  Cancel
                </IonButton>
                <IonButton
                  type="submit"
                  color="success"
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting ? <IonSpinner /> : "Add"}
                </IonButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <IonList className="p-2 overflow-y-scroll max-h-[200px]">
        <IonListHeader>Preloaded users </IonListHeader>
        {whitelist.map((item: any) => (
          <IonItem key={item.id}>
            {item.id} - {item.email}
          </IonItem>
        ))}
      </IonList>
    </IonModal>
  );
};

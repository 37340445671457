import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc
} from "firebase/firestore";
import create from "zustand";
import { db } from "../lib/firebase";
import { Transaction } from "../types/transaction.,type";

interface TransactionsState {
  transactions: Transaction[];
  fetching: boolean;
  addTransaction: (transaction: Transaction) => Promise<void>;
  updateTransaction: (
    id: string,
    fields: { [index: string]: any }
  ) => Promise<void>;
}

export const useTransactionsStore = create<TransactionsState>((set, get) => ({
  transactions: [],
  fetching: true,
  addTransaction: async (transaction: Transaction) => {
    const q = query(collection(db, "transactions"));
    const querySnapshot = await getDocs(q);
    const latestTransactionId: any =
      querySnapshot.docs.sort((a, b) => parseInt(b.id) - parseInt(a.id))[0]
        ?.id || 0;
    await setDoc(
      doc(db, "transactions", (parseInt(latestTransactionId) + 1).toString()),
      transaction
    );
  },
  updateTransaction: async (id: string, fields: { [index: string]: any }) => {
    const transaction = get().transactions.find(t => t.id === id);
    if (!transaction) return;
    if (
      fields.status.payment.complete !== transaction.status.payment.complete
    ) {
      fields.status.payment.updated = new Date();
    }
    if (
      fields.status.documentation.complete !==
      transaction.status.documentation.complete
    ) {
      fields.status.documentation.updated = new Date();
    }
    fields.status.complete =
      fields.status.payment.complete && fields.status.documentation.complete;
    const ref = doc(db, "transactions", id);
    await updateDoc(ref, { ...fields, updatedAt: new Date() });
  }
}));

onSnapshot(query(collection(db, "transactions")), querySnapshot => {
  useTransactionsStore.setState({ fetching: true });
  let tempTransactions: Transaction[] = [];
  querySnapshot.forEach(doc => {
    tempTransactions.push(toTransaction(doc));
  });
  useTransactionsStore.setState({
    transactions: tempTransactions,
    fetching: false
  });
});

function toTransaction(doc: any): Transaction {
  let transaction: any = { ...doc.data(), id: doc.id };
  transaction.timestamp = transaction.timestamp?.toDate();
  if (transaction.status) {
    transaction.status.payment.updated =
      transaction.status?.payment?.updated?.toDate();
    transaction.status.documentation.updated =
      transaction.status?.documentation?.updated?.toDate();
  }
  return transaction as Transaction;
}

import create from "zustand";
import { onAuthStateChanged, User as FirebaseUser } from "firebase/auth";
import { auth, db } from "../lib/firebase";
import { collection, onSnapshot } from "firebase/firestore";

interface AuthState {
  user: FirebaseUser | null;
  whitelist: any[];
  loading: boolean;
}

export const useAuthStore = create<AuthState>(set => ({
  user: null,
  loading: true,
  whitelist: []
}));

onAuthStateChanged(auth, user => {
  useAuthStore.setState({ user: user, loading: false });
});

export const getWhitelistSnapshot = (callback: any) => {
  onSnapshot(collection(db, "whitelist"), snapshot => {
    const whitelist: any[] = [];
    snapshot.forEach(doc => {
      whitelist.push({ ...doc.data(), id: doc.id });
    });
    callback(whitelist);
  });
};

getWhitelistSnapshot((whitelist: any) => {
  useAuthStore.setState({ whitelist: whitelist });
});

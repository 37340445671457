import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyAZ8tDA7qXaU-2Z_LR8fGuTNdOUwrPktvU",
  authDomain: "property-investment-fa2a5.firebaseapp.com",
  projectId: "property-investment-fa2a5",
  storageBucket: "property-investment-fa2a5.appspot.com",
  messagingSenderId: "119756884085",
  appId: "1:119756884085:web:3dfba0855577fea7ff903c",
  measurementId: "G-GVWYX17YG8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage();

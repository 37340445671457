import { IonButton, IonSpinner } from "@ionic/react";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router";

import Layout from "../components/Layout";
import Table, { Column } from "../components/Table";
import { db } from "../lib/firebase";
import { toCurrency } from "../lib/utils";
import { useAssetsStore } from "../stores/assetsStore";
import { EmptyAsset } from "../types/asset.type";

const columns: Column[] = [
  {
    accessor: "name",
    label: "Name:",
    type: "string"
  },
  {
    accessor: "city",
    label: "City:",
    type: "string"
  },
  {
    accessor: "value",
    label: "Value:",
    type: "number",
    formatter: toCurrency
  }
];
const AssetManagement: React.FC = () => {
  const [assets, fetching] = useAssetsStore(state => [
    state.assets,
    state.fetching
  ]);
  const history = useHistory();

  const [creating, setCreating] = useState(false);

  const addAsset = async () => {
    setCreating(true);
    const newDoc = await addDoc(collection(db, "assets"), EmptyAsset);
    const id = newDoc.id;
    toast.success("Asset added");
    setCreating(false);
    history.push(`/assets/${id}`);
  };
  return (
    <Layout title="Asset Management">
      <div className="flex items-center justify-end px-2 abs">
        <IonButton fill="outline" disabled={creating} onClick={addAsset}>
          {creating ? <IonSpinner /> : "Add Asset"}
        </IonButton>
      </div>
      <Table
        allData={assets}
        loading={fetching}
        columns={columns}
        onRowClick={asset => history.push(`/assets/${asset.id}`)}
      />
    </Layout>
  );
};

export default AssetManagement;
